import { costInsightsApiRef } from '@internal/plugin-cost-insights';
import { AwesomeCostInsightsClient } from './components/costInsightsClient/CostInsightsClient';
import {
  scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  DiscoveryApi,
  discoveryApiRef,
  FetchApi,
  fetchApiRef,
  githubAuthApiRef,
} from '@backstage/core-plugin-api';
import { ScmAuth } from '@backstage/integration-react';
import {
  techRadarApiRef,
  TechRadarApi,
  TechRadarLoaderResponse,
} from '@backstage-community/plugin-tech-radar';

class TechRadarApiImpl implements TechRadarApi {
  constructor(
    private fetchApi: FetchApi,
    private discoveryApi: DiscoveryApi,
  ) {}
  async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('techradar');
    const response = await this.fetchApi.fetch(`${baseUrl}/radars/${id}`);
    return (await response.json()) as Promise<TechRadarLoaderResponse>;
  }
}

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: { fetchApi: fetchApiRef, discoveryApi: discoveryApiRef },
    factory: ({ fetchApi, discoveryApi }) =>
      new TechRadarApiImpl(fetchApi, discoveryApi),
  }),
  createApiFactory({
    api: costInsightsApiRef,
    deps: {
      configApi: configApiRef,
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
    },
    // eslint-disable-next-line new-cap
    factory: ({ configApi, discoveryApi, fetchApi }) =>
      new AwesomeCostInsightsClient(configApi, discoveryApi, fetchApi),
  }),
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) =>
      ScmAuth.merge(ScmAuth.forGithub(githubAuthApi)),
  }),
];
